import constants from '../Utils/constants';
const initialState = {
  isCreateEventLayoutLoading: false,
  isGetEventLayoutByIdLoading: false,
  isUpdateEventLayoutLoading: false,
  isGetLayoutByIdLoading: false,
  isLayoutRowUpdateLoading: false,
  isImportLayoutFileLoading: false,
  isGetSeatByIdLoading: false,
  isSearchingVisitorsLoading: false,
  isSeatAssignLoading: false,
  isExportLayoutFileLoading: false,
  isDeleteVisitorsLoading: false,
  isDeleteSeatLoading: false,
  eventLayoutById: [],
  layoutById: {},
  getSeatById: {},
  searchResults: [],
  exportFileLayout: {},
};

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_EVENT_LAYOUT_REQUEST:
      return {
        ...state,
        isCreateEventLayoutLoading: true,
      };
    case constants.CREATE_EVENT_LAYOUT_SUCCESS:
      return {
        ...state,
        isCreateEventLayoutLoading: false,
      };
    case constants.CREATE_EVENT_LAYOUT_FAILURE:
      return {
        ...state,
        isCreateEventLayoutLoading: false,
      };

    case constants.GET_EVENT_LAYOUT_BY_ID_REQUEST:
      return {
        ...state,
        isGetEventLayoutByIdLoading: true,
      };
    case constants.GET_EVENT_LAYOUT_BY_ID_SUCCESS:
      return {
        ...state,
        isGetEventLayoutByIdLoading: false,
        eventLayoutById: action.payload,
      };
    case constants.GET_EVENT_LAYOUT_BY_ID_FAILURE:
      return {
        ...state,
        isGetEventLayoutByIdLoading: false,
      };

    case constants.UPDATE_EVENT_LAYOUT_REQUEST:
      return {
        ...state,
        isUpdateEventLayoutLoading: true,
      };
    case constants.UPDATE_EVENT_LAYOUT_SUCCESS:
      return {
        ...state,
        isUpdateEventLayoutLoading: false,
      };
    case constants.UPDATE_EVENT_LAYOUT_FAILURE:
      return {
        ...state,
        isUpdateEventLayoutLoading: false,
      };

    case constants.GET_LAYOUT_BY_ID_REQUEST:
      return {
        ...state,
        isGetLayoutByIdLoading: true,
      };

    case constants.GET_LAYOUT_BY_ID_SUCCESS:
      return {
        ...state,
        isGetLayoutByIdLoading: false,
        layoutById: action.payload,
      };

    case constants.GET_LAYOUT_BY_ID_FAILURE:
      return {
        ...state,
        isGetLayoutByIdLoading: false,
      };

    case constants.CREATE_LAYOUT_ROW_REQUEST:
      return {
        ...state,
        isLayoutRowCreatingLoading: true,
      };
    case constants.CREATE_LAYOUT_ROW_SUCCESS:
      return {
        ...state,
        isLayoutRowCreatingLoading: false,
      };
    case constants.CREATE_LAYOUT_ROW_FAILURE:
      return {
        ...state,
        isLayoutRowCreatingLoading: false,
      };

    case constants.UPDATE_LAYOUT_ROW_REQUEST:
      return {
        ...state,
        isLayoutRowUpdateLoading: true,
      };
    case constants.UPDATE_LAYOUT_ROW_SUCCESS:
      return {
        ...state,
        isLayoutRowUpdateLoading: false,
      };
    case constants.UPDATE_LAYOUT_ROW_FAILURE:
      return {
        ...state,
        isLayoutRowUpdateLoading: false,
      };

    case constants.IMPORT_LAYOUT_FILE_REQUEST:
      return {
        ...state,
        isImportLayoutFileLoading: true,
      };
    case constants.IMPORT_LAYOUT_FILE_SUCCESS:
      return {
        ...state,
        isImportLayoutFileLoading: false,
      };
    case constants.IMPORT_LAYOUT_FILE_FAILURE:
      return { ...state, isImportLayoutFileLoading: false };

    case constants.EXPORT_LAYOUT_FILE_REQUEST:
      return {
        ...state,
        isExportLayoutFileLoading: true,
      };
    case constants.EXPORT_LAYOUT_FILE_SUCCESS:
      return {
        ...state,
        isExportLayoutFileLoading: false,
        exportFileLayout: action.payload,
      };
    case constants.EXPORT_LAYOUT_FILE_FAILURE:
      return {
        ...state,
        isExportLayoutFileLoading: false,
      };
    case constants.GET_SEAT_BY_ID_REQUEST:
      return {
        ...state,
        isGetSeatByIdLoading: true,
      };
    case constants.GET_SEAT_BY_ID_SUCCESS:
      return {
        ...state,
        isGetSeatByIdLoading: false,
        getSeatById: action.payload,
      };

    case constants.GET_SEAT_BY_ID_FAILURE:
      return { ...state, isGetSeatByIdLoading: false };

    case constants.UPDATE_SEAT_REQUEST:
      return {
        ...state,
        isUpdatSeatLoading: true,
      };
    case constants.UPDATE_SEAT_SUCCESS:
      return {
        ...state,
        isUpdatSeatLoading: false,
      };

    case constants.UPDATE_SEAT_FAILURE:
      return {
        ...state,
        isUpdatSeatLoading: false,
      };

    case constants.SEARCH_VISITORS_REQUEST:
      return {
        ...state,
        isSearchingVisitorsLoading: true,
      };
    case constants.SEARCH_VISITORS_SUCCESS:
      return {
        ...state,
        isSearchingVisitorsLoading: false,
        searchVisitiors: action.payload,
      };
    case constants.SEARCH_VISITORS_FAILURE:
      return {
        ...state,
        isSearchingVisitorsLoading: false,
      };

    case constants.ASSIGN_SEAT_REQUEST:
      return {
        ...state,
        isSeatAssignLoading: true,
      };
    case constants.ASSIGN_SEAT_SUCCESS:
      return {
        ...state,
        isSeatAssignLoading: false,
      };
    case constants.ASSIGN_SEAT_FAILURE:
      return {
        ...state,
        isSeatAssignLoading: false,
      };

    case constants.DELETE_VISITORS_REQUEST:
      return {
        ...state,
        isDeleteVisitorsLoading: true,
      };
    case constants.DELETE_VISITORS_SUCCESS:
      return {
        ...state,
        isDeleteVisitorsLoading: false,
      };
    case constants.DELETE_VISITORS_FAILURE:
      return {
        ...state,
        isDeleteVisitorsLoading: false,
      };

    case constants.DELETE_SEAT_REQUEST:
      return {
        ...state,
        isDeleteSeatLoading: true,
      };
    case constants.DELETE_SEAT_SUCCESS:
      return {
        ...state,
        isDeleteSeatLoading: false,
      };
    case constants.DELETE_SEAT_FAILURE:
      return {
        ...state,
        isDeleteSeatLoading: false,
      };

    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
