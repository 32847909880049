import React, { useEffect, useState } from 'react';
import './Layout.css';
import {
  Breadcrumb,
  Button,
  Col,
  Empty,
  Row,
  Spin,
  Tooltip,
  Typography,
  Input,
} from 'antd';
import 'antd/dist/antd.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteVisitorsAction,
  ExportLayoutFileAction,
  getLayoutByIdAction,
  searchLayoutRowAction,
} from '../Actions/layoutAction';
const { Search } = Input;
import CreateRowModel from './CreateRowModel';
import {
  EditOutlined,
  LayoutOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  falseyValueCases,
  newImageUrl,
} from '../Screens/CommonLogics/CommonMethods';
import AssignGuestModel from './AssignGuestModel';
import LoadingIndicator from '../Screens/LoadingIndicator/LoadingIndicator';
import { capitalize } from 'lodash';
// import { history } from '../Utils/history';

const LayoutByIdComponent = () => {
  const {
    layoutById,
    exportFileLayout,
    isExportLayoutFileLoading,
    isGetLayoutByIdLoading,
    isDeleteVisitorsLoading,
  } = useSelector((state) => {
    const { layout } = state;
    const {
      layoutById,
      exportFileLayout,
      isExportLayoutFileLoading,
      isGetLayoutByIdLoading,
      isDeleteVisitorsLoading,
    } = layout;
    return {
      layoutById,
      exportFileLayout,
      isExportLayoutFileLoading,
      isGetLayoutByIdLoading,
      isDeleteVisitorsLoading,
    };
  });
  const [layoutSectionData, setLayoutSectionData] = useState(layoutById);
  const [isAssignSeatModelOpen, setIsAssignSeatModelOpen] = useState({
    isAssignSeatModelopen: false,
    assignSeatdata: null,
    actionType: null,
    selectedSection: null,
  });
  const { isAssignSeatModelopen, assignSeatdata, actionType, selectedSection } =
    isAssignSeatModelOpen;
  const [isModelOpen, setIsModelOpen] = useState({
    isModelopen: false,
    modalData: null,
    type: 'null',
    modelType: null,
  });
  const { isModelopen, modalData, type, modelType } = isModelOpen;

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleModelOpen = (isOpen, type, data, modelType) => {
    setIsModelOpen({
      isModelopen: isOpen,
      modalData: data,
      type: type,
      modelType: modelType,
    });
  };
  const handleAssignSeat = (isOpen, data, actionType, section) => {
    setIsAssignSeatModelOpen({
      isAssignSeatModelopen: isOpen,
      assignSeatdata: data,
      actionType: actionType,
      selectedSection: section,
    });
  };
  const onRowSearch = (data, _e, info) => {
    const searchTerm = data.target.value?.trim();
    console.log(
      searchTerm,
      data.target.defaultValue,
      layoutById?.sections?.filter((section) => {
        return section?.name?.toLowerCase().includes(searchTerm?.toLowerCase());
      })
    );

    if (searchTerm && searchTerm.length > 0) {
      const filteredSections = layoutById?.sections?.filter((section) =>
        section?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );

      setLayoutSectionData({
        ...layoutById,
        sections: filteredSections,
      });
    } else {
      setLayoutSectionData(layoutById);
    }
  };

  useEffect(() => {
    dispatch(getLayoutByIdAction(id));
  }, []);
  useEffect(() => {
    setLayoutSectionData(layoutById);
  }, [layoutById]);
  const ExportLayoutSuccessCallback = async (res) => {
    const fileUrl = await newImageUrl(res?.key);
    window.open(fileUrl);
  };
  const ExportLayoutFailureCallback = () => {};

  const handleExportFile = () => {
    dispatch(
      ExportLayoutFileAction(
        id,
        ExportLayoutSuccessCallback,
        ExportLayoutFailureCallback
      )
    );
  };

  const onDeleteVisitorsSuccess = () => {
    dispatch(getLayoutByIdAction(id));
  };
  const handleDeleteVisitores = () => {
    dispatch(deleteVisitorsAction(onDeleteVisitorsSuccess));
  };

  return (
    <div className='LayoutContainer'>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => history.back()}
              style={{ cursor: 'pointer' }}
            >
              Layout
            </Breadcrumb.Item>
            <Breadcrumb.Item>{layoutById?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24}>
          <Row justify='space-between' align='middle' gutter={[10, 0]}>
            <Col>
              <Typography className='LayoutTitle'>
                {layoutById?.name}
              </Typography>
            </Col>
            <Col>
              <Row gutter={[20, 20]}>
                <Col>
                  <Button
                    type='primary'
                    onClick={() =>
                      handleModelOpen(true, 'create', null, 'fileImport')
                    }
                    style={{ borderRadius: '4px', width: '100%' }}
                    className='button-search'
                  >
                    Import file
                  </Button>
                </Col>
                {layoutById?.total_visitors !== 0 && (
                  <>
                    {' '}
                    <Col>
                      <Button
                        type='primary'
                        onClick={() => handleExportFile()}
                        style={{ borderRadius: '4px', width: '100%' }}
                        className='button-search'
                        disabled={isExportLayoutFileLoading}
                      >
                        {isExportLayoutFileLoading ? (
                          <Spin
                            indicator={<LoadingOutlined spin />}
                            size='small'
                          />
                        ) : (
                          ' Export file'
                        )}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type='primary'
                        onClick={() => handleDeleteVisitores()}
                        style={{ borderRadius: '4px', width: '100%' }}
                        className='button-search'
                        disabled={isDeleteVisitorsLoading}
                      >
                        {isDeleteVisitorsLoading ? (
                          <Spin
                            indicator={<LoadingOutlined spin />}
                            size='small'
                          />
                        ) : (
                          'Delete all visitors'
                        )}
                      </Button>
                    </Col>{' '}
                  </>
                )}
                <Col>
                  <Button
                    type='primary'
                    onClick={() => handleModelOpen(true, 'create', null, 'row')}
                    style={{ borderRadius: '4px', width: '100%' }}
                    className='button-search'
                  >
                    Create row
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify='space-between' align='middle'>
            <Col>
              <Row justify='start' gutter={[10, 0]}>
                <Col>
                  <Typography className='LayoutShortDetails'>
                    Total seats: {layoutById?.total_seat ?? 0}
                  </Typography>
                </Col>
                <Col>
                  <Typography className='LayoutShortDetails'>|</Typography>
                </Col>
                <Col>
                  <Typography className='LayoutShortDetails'>
                    Available seats: {layoutById?.available_seats ?? 0}
                  </Typography>
                </Col>
                <Col>
                  <Typography className='LayoutShortDetails'>|</Typography>
                </Col>
                <Col>
                  <Typography className='LayoutShortDetails'>
                    Occupied seats: {layoutById?.occupied_seats ?? 0}
                  </Typography>
                </Col>
                <Col>
                  <Typography className='LayoutShortDetails'>|</Typography>
                </Col>
                <Col>
                  <Typography className='LayoutShortDetails'>
                    Total visitors: {layoutById?.total_visitors ?? 0}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col>
              <Search
                onChange={onRowSearch}
                placeholder='Search for specific row'
                onSearch={onRowSearch}
                style={{
                  width: 200,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <LoadingIndicator loading={isGetLayoutByIdLoading}>
            <div className='seating-chart'>
              {layoutSectionData?.sections?.length > 0 ? (
                layoutSectionData?.sections?.map((section, index) => {
                  const occupiedSeats = section.seats.filter(
                    (seat) => !falseyValueCases(seat.visitor)
                  ).length;
                  const availableSeats = section.seats.length - occupiedSeats;
                  return (
                    <div key={index} className='section'>
                      <></>
                      <Tooltip
                        key={section.id}
                        title={
                          <>
                            {' '}
                            Total seats: {section?.seats.length} <br /> Occupied
                            seats: {occupiedSeats} <br />
                            Available seats: {availableSeats}
                          </>

                          // <br />
                          // Name: {seat.visitor.name}
                        }
                      >
                        <div style={{ paddingRight: '15px' }}>
                          <Typography className='section-name'>
                            {section.name}
                          </Typography>
                        </div>
                      </Tooltip>

                      <div className='seats-wrapper'>
                        <div className='seats'>
                          {section.seats.map((seat) => (
                            <Tooltip
                              key={seat.id}
                              title={
                                seat.visitor.name ? (
                                  <>
                                    Syid : {seat?.visitor?.syid}
                                    <br />
                                    Seat: {seat?.name}
                                    <br />
                                    Name: {seat?.visitor?.name}
                                    <br />
                                    City: {seat?.visitor?.city}
                                    <br />
                                    State: {seat?.visitor?.state}
                                    <br />
                                    State: {seat?.visitor?.country}
                                  </>
                                ) : (
                                  <>
                                    Seat: {seat.name}
                                    <br />
                                    Available
                                  </>
                                )
                              }
                            >
                              <div
                                className={`seat ${
                                  !falseyValueCases(seat.visitor)
                                    ? 'occupied'
                                    : 'available'
                                }`}
                                onClick={() =>
                                  handleAssignSeat(
                                    true,
                                    seat,
                                    'create',
                                    section
                                  )
                                }
                              >
                                {seat.name}
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                        <div className='icons'>
                          {/* <PlusCircleOutlined
                        style={{
                          fontSize: '20px',
                          color: '#4593EF',
                          marginRight: '10px',
                          cursor: 'pointer',
                        }}
                      /> */}
                          <EditOutlined
                            style={{
                              fontSize: '20px',
                              color: '#4593EF',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              handleModelOpen(true, 'edit', section, 'row')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Row justify='center' align='middle'>
                  <Col span={24}>
                    <div className='EmptyScreenCommunityCards'>
                      <Empty
                        description={
                          <Typography className='empty-details'>
                            Seat not found
                          </Typography>
                        }
                        image={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 64 64'
                            width='64'
                            height='64'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          >
                            <rect
                              x='20'
                              y='5'
                              width='24'
                              height='20'
                              rx='2'
                              ry='2'
                              fill='#B0C4DE'
                            />

                            <rect
                              x='15'
                              y='25'
                              width='34'
                              height='8'
                              rx='2'
                              ry='2'
                              fill='#4682B4'
                            />

                            <rect
                              x='18'
                              y='33'
                              width='4'
                              height='20'
                              rx='1'
                              ry='1'
                              fill='#6A5ACD'
                            />

                            <rect
                              x='42'
                              y='33'
                              width='4'
                              height='20'
                              rx='1'
                              ry='1'
                              fill='#6A5ACD'
                            />

                            <rect
                              x='22'
                              y='48'
                              width='20'
                              height='3'
                              rx='1.5'
                              ry='1.5'
                              fill='#6A5ACD'
                            />
                          </svg>
                        }
                      ></Empty>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </LoadingIndicator>
        </Col>
      </Row>
      <CreateRowModel
        open={isModelopen}
        data={modalData}
        type={type}
        modelType={modelType}
        layoutId={id}
        onClose={() => handleModelOpen(false, null, null, null)}
      />
      {isAssignSeatModelopen && (
        <AssignGuestModel
          open={isAssignSeatModelopen}
          data={assignSeatdata}
          type={actionType}
          sectionData={selectedSection}
          layoutId={id}
          onClose={() => handleAssignSeat(false, null, null)}
        />
      )}
    </div>
  );
};

export default LayoutByIdComponent;
