import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../Screens/LoadingIndicator/LoadingIndicator';
import {
  Button,
  Col,
  Drawer,
  Input,
  notification,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './Layout.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  crateLayoutRowAction,
  createEventLayout,
  getLayoutByIdAction,
  importLayoutFileAction,
  upateEventLayoutAction,
  updateLayoutRowAction,
} from '../Actions/layoutAction';
import { LinkOutlined, LoadingOutlined } from '@ant-design/icons';
import { toNumber } from 'lodash';

const CreateRowModel = (props) => {
  const { open, onClose, layoutId, type, data, modelType } = props;
  const {
    isLayoutRowCreatingLoading,
    isLayoutRowUpdateLoading,
    isImportLayoutFileLoading,
  } = useSelector((state) => {
    const { layout } = state;
    const {
      isLayoutRowCreatingLoading,
      isLayoutRowUpdateLoading,
      isImportLayoutFileLoading,
    } = layout;
    return {
      isLayoutRowCreatingLoading,
      isLayoutRowUpdateLoading,
      isImportLayoutFileLoading,
    };
  });
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dragOver, setDragOver] = useState(false);
  const [fileName, setFileName] = useState('');
  const [files, setFiles] = useState();

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const files = e.dataTransfer.files;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFiles(file);
    setFileName(file.name);

    e.target.value = null;
  };
  // console.log({ files });
  // e.preventDefault();
  // setDragOver(false);
  // console.log('handleFileChange2', { e });

  const onFormClear = () => {
    reset();
  };
  const successCallback = (res, type) => {
    if (res?.message) {
      notification.open({
        message: 'Success',
        description: res?.message,
        duration: 5,
      });
    }

    dispatch(getLayoutByIdAction(layoutId));

    setFiles();
    setFileName();
    reset();
    onClose();
  };
  const failureCallback = (error) => {
    console.log('failureCallback', { error });
  };

  const onformSubmit = (formData) => {
    if (modelType === 'fileImport') {
      dispatch(
        importLayoutFileAction(
          layoutId,
          files,
          successCallback,
          failureCallback
        )
      );
    } else {
      if (type === 'create') {
        formData.layout_id = layoutId;
        formData.no_of_seats = toNumber(formData?.no_of_seats);
        formData.starting_from = toNumber(formData?.starting_from);
        dispatch(
          crateLayoutRowAction(
            formData,
            (res) => successCallback(res, 'getRow'),
            failureCallback
          )
        );
      } else if (type === 'edit') {
        console.log({ formData }, data?.id);
        formData.no_of_seats = toNumber(formData?.no_of_seats);
        formData.starting_from = toNumber(formData?.starting_from);
        dispatch(
          updateLayoutRowAction(
            data?.id,
            formData,
            (res) => successCallback(res, 'getRow'),
            failureCallback
          )
        );
      }
    }
  };

  const loading =
    isLayoutRowUpdateLoading ||
    isLayoutRowCreatingLoading ||
    isImportLayoutFileLoading;
  return (
    <Drawer
      open={open}
      footer={null}
      onClose={() => {
        onClose();
        reset();
      }}
      title={`${type === 'edit' ? 'Edit' : 'Create'} event layout`}
    >
      <form onSubmit={handleSubmit(onformSubmit)}>
        {modelType === 'row' ? (
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Typography className='FormInputLabel'>Row name</Typography>
              <Controller
                as={
                  <Input
                    size='large'
                    className='inputLabel'
                    placeholder='Enter row name'
                    disabled={type === 'edit'}
                  />
                }
                rules={{
                  required: 'layout name required',
                }}
                defaultValue={data?.name || ''}
                name='name'
                control={control}
              />
              {errors?.name?.message && (
                <p style={{ color: 'red' }}>{errors?.name?.message}</p>
              )}
            </Col>

            <Col span={24}>
              <Typography className='FormInputLabel'>Total seats</Typography>
              <Controller
                as={
                  <Input
                    size='large'
                    className='inputLabel'
                    placeholder='Enter total number of seats in this row'
                    type='number'
                  />
                }
                rules={{
                  required: 'layout name required',
                  valueAsNumber: true,
                }}
                name='no_of_seats'
                control={control}
              />
              {errors?.name?.message && (
                <p style={{ color: 'red' }}>{errors?.name?.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography className='FormInputLabel'>
                Row start value
              </Typography>
              <Controller
                as={
                  <Input
                    size='large'
                    className='inputLabel'
                    placeholder='Enter the start value of this row'
                    type='number'
                  />
                }
                rules={{
                  required: 'layout name required',
                  valueAsNumber: true,
                }}
                name='starting_from'
                control={control}
              />
              <Typography className='inputFeildDiscription'>
                For eg: Row A have 10 seats starting from number 1, so the start
                value is 1
              </Typography>
              {errors?.name?.message && (
                <p style={{ color: 'red' }}>{errors?.name?.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Button
                    onClick={onFormClear}
                    className='allButtonsCancel'
                    style={{ width: '100%' }}
                  >
                    {t('btn.19')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType='submit'
                    className='allButtons'
                    style={{ width: '100%' }}
                  >
                    {loading ? (
                      <Spin indicator={<LoadingOutlined spin />} size='small' />
                    ) : type === 'edit' ? (
                      t('btn.27')
                    ) : (
                      t('btn.18')
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : modelType === 'fileImport' ? (
          <>
            <div
              style={{
                border: '1px solid #262626',
                backgroundColor: dragOver ? '#f6f6f6' : '#ffffff',
                padding: '20px',
              }}
            >
              <label
                htmlFor='file-upload-input'
                style={{ width: '100%' }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <Row justify='center' align='middle' gutter={[0, 20]}>
                  <Col>
                    <Row
                      container
                      justify='center'
                      align='middle'
                      direction='column'
                    >
                      <Col>
                        <Typography className='uploadFileText'>
                          Click or drag image file to this area to upload
                        </Typography>
                      </Col>
                      <Col>
                        <Typography className='uploadFileSubText'>
                          Supports .xlsx ,.csv
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </label>
              <input
                id='file-upload-input'
                type='file'
                accept='.xlsx ,.csv'
                onChange={handleFileChange}
                hidden
              />
            </div>
            {fileName && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: '5px',
                  }}
                >
                  <LinkOutlined rotate={20} />
                  <Typography className='fileName'>{fileName}</Typography>
                </div>
                <Col span={24} style={{ paddingTop: '10px' }}>
                  <Row gutter={[10, 0]}>
                    <Col span={12}>
                      <Button
                        onClick={onFormClear}
                        className='allButtonsCancel'
                        style={{ width: '100%' }}
                      >
                        {t('btn.19')}
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        htmlType='submit'
                        className='allButtons'
                        style={{ width: '100%' }}
                      >
                        {loading ? (
                          <Spin
                            indicator={<LoadingOutlined spin />}
                            size='small'
                          />
                        ) : type === 'edit' ? (
                          t('btn.27')
                        ) : (
                          t('btn.18')
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </>
        ) : (
          ''
        )}
      </form>
    </Drawer>
  );
};

export default CreateRowModel;
