import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FindOtherPeople from './FindThePeoplePage/FindOtherPeople';
import Main from './Main';
import { history } from './Utils/history';
import Slider from './FindThePeoplePage/Slider';
import Event from './Events/Events';
import { Route, Switch } from 'react-router';
import Trustee from './Screens/TrusteeView/Trustee';
import Familybtn from './Screens/AddFamilymember/CreateFamily';
import MatrimonialPage from './FindThePeoplePage/Matrimonial/MatrimonialPage';
import BusinessProfile from './FindThePeoplePage/BusinessProfiles/BusinessProfile';
import JobProfile from './FindThePeoplePage/JobProfile/JobProfile';
import BusinessProfileDetails from './FindThePeoplePage/BusinessProfiles/BusinessProfileDetails';
import OtpAuthentication from './Screens/OtpAuthentication/OtpAuthentication';
import { getUnderMaintenanceAction } from './Actions/underMaintenanceAction';
import ViewMyMatches from './FindThePeoplePage/Matrimonial/ViewMyMatches';
import Admin from './Screens/Admin/Admin';
import VolunteerSideTable from './Screens/Volunteer/VolunteerSideTable';
import { PrivateRoute } from './Utils/PrivateRoute';
import EmptyScreen from './Screens/EmptyScreen';
import LandingPage from './Screens/LandingPage/LandingPage';
import TermsAndConditions from './Screens/TermsAndConditions/TermsAndConditions';
import Faqs from './Screens/LandingPage/Faqs';
import AboutUs from './Screens/LandingPage/AboutUs';
import Blogs from './Screens/LandingPage/Blogs';
import journey from './Screens/LandingPage/journey';
import Information from './Screens/Information/Information';
import HelpAndSupport from './Screens/HelpAndSupport/HelpAndSupport';
import CommunityFeeds from './Screens/CommunityFeeds/CommunityFeeds';
import Disclaimer from './Screens/LandingPage/Disclaimer';
import PrivacyPolicy from './Screens/LandingPage/PrivacyPolicy';
import UnderMaintainence from './Screens/UnderMaintainence/UnderMaintainence';
import LoadingIndicator from './Screens/LoadingIndicator/LoadingIndicator';
import Howto from './Screens/LandingPage/Howto';
import HowtoDetails from './Screens/LandingPage/HowtoDetails';
import ViewMemberDetails from './Screens/AddFamilymember/familyDetails';
import ViewMemberDetails2 from './Screens/AddFamilymember/ViewMemberDetails2';
import MatrimonialProfileDetails from './FindThePeoplePage/Matrimonial/MatrimonialProfileDetails';
import ViewFamilyDetails from './Screens/AddFamilymember/ViewFamilyDetails';
import LayoutComponent from './Layout/LayoutComponent';
import LayoutByIdComponent from './Layout/LayoutByIdComponent';

const UserRoutes = () => {
  const token = localStorage.getItem('access_token');
  const dispatch = useDispatch();
  const [allUnderMaintenanceWindows, setAllUnderMaintenanceWindows] = useState(
    []
  );

  const [isUnderMaintenanceLoading, setIsUnderMaintenanceLoading] =
    useState(true);

  useEffect(() => {
    dispatch(getUnderMaintenanceAction(UnderMaintainenceSuccessCallback));

    let timer = setTimeout(() => {
      setIsUnderMaintenanceLoading(false);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const UnderMaintainenceSuccessCallback = (SuccessResponse) => {
    setAllUnderMaintenanceWindows(SuccessResponse);
  };

  const isUnderMaintenance =
    allUnderMaintenanceWindows?.length > 0 ? true : false;

  useEffect(() => {
    if (history.location.pathname === '/' && token) {
      history.push('/add_family');
    } else if (isUnderMaintenance) {
      history.push('/under_maintenance');
    } else {
    }
  }, [isUnderMaintenance]);

  return (
    <LoadingIndicator isIntroLoader={isUnderMaintenanceLoading}>
      <Switch>
        <Route
          exact
          path={isUnderMaintenance ? '/under_maintenance' : '/'}
          component={isUnderMaintenance ? UnderMaintainence : LandingPage}
        ></Route>
        <Route path='/about_us' component={AboutUs}></Route>
        <Route path='/blogs' component={Blogs}></Route>
        <Route path='/howto' component={Howto}></Route>
        <Route path='/blog/:id' component={journey}></Route>
        <Route path='/howtodetails/:id' component={HowtoDetails}></Route>
        <Route exact path='/login' component={Main}></Route>
        <Route path='/verify' component={OtpAuthentication}></Route>
        <Route path='/terms' component={TermsAndConditions}></Route>
        <Route path='/faqs' component={Faqs}></Route>

        <PrivateRoute
          exact
          path='/communities/:id/informations'
          component={() => <Slider component={Information} />}
        ></PrivateRoute>

        <Route path='/communities' component={journey}></Route>
        <Route path='/disclaimer' component={Disclaimer}></Route>
        <Route path='/privacy_policy' component={PrivacyPolicy}></Route>
        <PrivateRoute
          exact
          path='/search'
          component={() => <Slider component={FindOtherPeople} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/trustees'
          component={() => <Slider component={Trustee} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/events'
          component={() => <Slider component={Event} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/events/layout/:id'
          component={LayoutComponent}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/events/viewlayout/:id'
          component={LayoutByIdComponent}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/matrimonials'
          component={() => <Slider component={MatrimonialPage} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/matrimonials/:id'
          component={() => <Slider component={MatrimonialProfileDetails} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/business_profiles'
          component={() => <Slider component={BusinessProfile} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/businessProfileDetails/:id'
          component={() => <Slider component={BusinessProfileDetails} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/jobProfile'
          component={() => <Slider component={JobProfile} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/viewMyMatches'
          component={() => <Slider component={ViewMyMatches} />}
        ></PrivateRoute>
        {/* <PrivateRoute
          exact
          path='/businessProfileDetails'
          component={() => <Slider component={BusinessProfileDetails} />}
        ></PrivateRoute> */}
        <PrivateRoute
          exact
          path='/add_family'
          component={() => <Slider component={Familybtn} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/families/:id'
          component={() => <Slider component={ViewFamilyDetails} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/user/:id'
          component={() => <Slider component={ViewMemberDetails2} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/admin'
          component={() => <Slider component={Admin} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/new_volunteers'
          component={() => <Slider component={VolunteerSideTable} />}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path='/support'
          component={() => <Slider component={HelpAndSupport} />}
        ></PrivateRoute>
        {/* 
        <PrivateRoute
          exact
          path='/informations'
          component={() => <Slider component={Information} />}
        ></PrivateRoute> */}

        <PrivateRoute
          exact
          path='/community_feeds'
          component={() => <Slider component={CommunityFeeds} />}
        ></PrivateRoute>
        <Route exact path='*' component={EmptyScreen}></Route>
      </Switch>
    </LoadingIndicator>
  );
};
export default UserRoutes;
