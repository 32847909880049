import React, { useEffect, useRef, useState } from 'react';
import LoadingIndicator from '../Screens/LoadingIndicator/LoadingIndicator';
import {
  AutoComplete,
  Button,
  Col,
  Drawer,
  Input,
  notification,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './Layout.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  AssignSeactToVisitorsAction,
  crateLayoutRowAction,
  createEventLayout,
  deleteLayoutSeatAction,
  getLayoutByIdAction,
  SearchVisitorsAction,
  upateEventLayoutAction,
  updateLayoutRowAction,
} from '../Actions/layoutAction';
import {
  DeleteFilled,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { debounce, isObject } from 'lodash';
import { falseyValueCases } from '../Screens/CommonLogics/CommonMethods';

const AssignGuestModel = (props) => {
  const { open, onClose, layoutId, type, data, modelType, sectionData } = props;
  const {
    isSeatAssignLoading,
    isDeleteVisitorsLoading,
    isSearchingVisitorsLoading,
    isDeleteSeatLoading,
  } = useSelector((state) => {
    const { layout } = state;
    const {
      isSeatAssignLoading,
      isDeleteVisitorsLoading,
      isSearchingVisitorsLoading,
      isDeleteSeatLoading,
    } = layout;
    return {
      isSeatAssignLoading,
      isDeleteVisitorsLoading,
      isSearchingVisitorsLoading,
      isDeleteSeatLoading,
    };
  });
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const autoCompleteRef = useRef(null);

  const [searchType, setSearchType] = useState(null);
  const [guestOptions, setGuestOptions] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState('');
  const [selectedGuestId, setSelectedGuestId] = useState();
  const [onSelectedMovetoSeat, setOnSelectedMovetoSeat] = useState();
  const [clearloading, setClearloading] = useState();
  const [visitiors, setVisitiors] = useState(data?.visitiors);

  // const [sectionSeat, setSectionSeat] = useState(
  //   sectionData?.seats?.map((data) => {
  //     return { label: data?.name, value: data?.id, ...data };
  //   })
  // );
  const sectionSeat = sectionData?.seats?.map((data) => {
    return { label: data?.name, value: data?.id, ...data };
  });
  console.log({ sectionData });
  const onClear = debounce(() => {
    setClearloading(false);
  }, 0);

  const onFormClear = () => {
    setGuestOptions([]);
    setClearloading(true);
    onClear();
    reset();
  };

  const successCallback = (res) => {
    dispatch(getLayoutByIdAction(layoutId));
    setGuestOptions([]);
    // setClearloading(true);
    // onClear();
    reset();
    onClose();
  };
  const failureCallback = (error) => {
    console.log('failureCallback', { error });
    console.log({ error });
  };
  const onformSubmit = (formData) => {
    console.log('onformSubmit', { formData });
    if (falseyValueCases(data?.visitor)) {
      formData.seat_id = data?.id;
      dispatch(
        AssignSeactToVisitorsAction(
          selectedGuestId,
          formData,
          successCallback,
          failureCallback
        )
      );
    } else {
      formData.seat_id = onSelectedMovetoSeat;
      dispatch(
        AssignSeactToVisitorsAction(
          selectedGuestId ? selectedGuestId : data?.visitor?.id,
          formData,
          successCallback,
          failureCallback
        )
      );
      console.log({ formData }, data?.id);
    }
  };
  const unAssignSeatHandler = () => {
    dispatch(
      AssignSeactToVisitorsAction(
        selectedGuestId ? selectedGuestId : data?.visitor?.id,
        { seat_id: null },
        successCallback,
        failureCallback
      )
    );
  };
  const deleteSeatAction = () => {
    dispatch(
      deleteLayoutSeatAction(data?.id, successCallback, failureCallback)
    );
  };
  const searchSucces = (res) => {
    console.log({ res });
    const filteredOptions = res?.map((item) => ({
      ...item,
      value: item?.name,
      label: item?.name,
    }));
    console.log({ filteredOptions });
    setGuestOptions(filteredOptions);
  };
  const serchFailure = () => {};
  const onGuestsearch = (value, type) => {
    let url = type === 'ID' ? `?syid=${value}` : `?name=${value}`;
    console.log({ url, type });
    dispatch(SearchVisitorsAction(url, searchSucces, serchFailure));
  };
  const handleSearchGuest = async (value) => {
    if (value) {
      console.log(value);
      setVisitiors(value);
      const type = /^SY\d+$/.test(value) ? 'ID' : 'Name';
      console.log({ type });
      setSearchType(type);

      onGuestsearch(value, type);
    } else {
      setGuestOptions([]);
    }
  };

  const onGuestSelect = (value, option) => {
    console.log('Selected:', { value, option });
    setSelectedGuest(option.label);
    setSelectedGuestId(option?.id);
  };
  const onSelectSeat = (value, option) => {
    setOnSelectedMovetoSeat(value);
  };
  console.log({ clearloading });
  const loading = isSeatAssignLoading;
  return (
    <Drawer
      open={open}
      footer={null}
      onClose={() => {
        // setClearloading(true);
        // onClear();
        onClose();
        reset();
      }}
      title={
        <Row justify='space-between'>
          <Col>
            {!falseyValueCases(data?.visitor)
              ? `Edit ${data?.name}`
              : `${data?.name} Assign guest`}
          </Col>
          <Col>
            <Row gutter={[30, 0]}>
              {' '}
              {!falseyValueCases(data?.visitor) && (
                <Col
                  onClick={unAssignSeatHandler}
                  style={{ cursor: 'pointer' }}
                >
                  <Tooltip title='Unassign seat'>
                    <svg
                      width='24'
                      height='23'
                      viewBox='0 0 24 23'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_27_6303)'>
                        <path
                          d='M10.5625 12.2188C12.9442 12.2188 14.875 10.288 14.875 7.90625C14.875 5.52452 12.9442 3.59375 10.5625 3.59375C8.18077 3.59375 6.25 5.52452 6.25 7.90625C6.25 10.288 8.18077 12.2188 10.5625 12.2188Z'
                          stroke='black'
                          stroke-width='1.5'
                          stroke-miterlimit='10'
                        />
                        <path
                          d='M4.09375 18.6875C4.09375 15.1146 6.98959 12.2188 10.5625 12.2188C11.9022 12.2188 13.1471 12.6263 14.1792 13.3235'
                          stroke='black'
                          stroke-width='1.5'
                          stroke-miterlimit='10'
                        />
                        <path
                          d='M17.75 22.2812C20.5287 22.2812 22.7812 20.0287 22.7812 17.25C22.7812 14.4713 20.5287 12.2188 17.75 12.2188C14.9713 12.2188 12.7188 14.4713 12.7188 17.25C12.7188 20.0287 14.9713 22.2812 17.75 22.2812Z'
                          stroke='black'
                          stroke-width='1.5'
                          stroke-miterlimit='10'
                        />
                        <path
                          d='M15.5938 19.4062L19.9062 15.0938'
                          stroke='black'
                          stroke-width='1.5'
                          stroke-miterlimit='10'
                        />
                        <path
                          d='M15.5938 15.0938L19.9062 19.4062'
                          stroke='black'
                          stroke-width='1.5'
                          stroke-miterlimit='10'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_27_6303'>
                          <rect
                            width='23'
                            height='23'
                            fill='white'
                            transform='translate(0.5)'
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Tooltip>
                </Col>
              )}
              <Col onClick={deleteSeatAction} style={{ cursor: 'pointer' }}>
                {isDeleteSeatLoading ? (
                  <Spin indicator={<LoadingOutlined spin />} size='small' />
                ) : (
                  <Tooltip title='Delete seat'>
                    <DeleteOutlined style={{ fontSize: '22px' }} />
                  </Tooltip>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <form onSubmit={handleSubmit(onformSubmit)}>
        <Row gutter={[0, 10]}>
          {/* {!falseyValueCases(data?.visitor) && (
            <Col span={24}>
              <Typography className='FormInputLabel'>Name</Typography>
              <Controller
                as={
                  <Input
                    size='large'
                    className='inputLabel'
                    placeholder='Enter guest full name'
                    disabled={type === 'edit'}
                  />
                }
                defaultValue={data?.visitor?.name || ''}
                name='name'
                control={control}
              />
              {errors?.name?.message && (
                <p style={{ color: 'red' }}>{errors?.name?.message}</p>
              )}
            </Col>
          )} */}

          <Col span={24}>
            <Typography className='FormInputLabel'>Guest id</Typography>
            {clearloading ? (
              <Spin indicator={<LoadingOutlined spin />} size='small' />
            ) : (
              <AutoComplete
                defaultValue={
                  !falseyValueCases(data?.visitor) && data?.visitor?.name
                }
                // value={visitiors}
                options={guestOptions}
                onSearch={handleSearchGuest}
                onSelect={onGuestSelect}
                style={{ width: '100%' }}
                dropdownStyle={{ width: '100%' }}
                notFoundContent={
                  isSearchingVisitorsLoading ? (
                    <Row justify='center'>
                      <Col>
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size='small'
                        />
                      </Col>
                    </Row>
                  ) : (
                    'Guest not found'
                  )
                }
              >
                <Input
                  placeholder='Search by ID or name'
                  style={{ width: '100%' }}
                />
              </AutoComplete>
            )}
          </Col>
          {!falseyValueCases(data?.visitor) && (
            <Col span={24}>
              <Typography className='FormInputLabel'>Move guest to</Typography>
              <AutoComplete
                options={sectionSeat}
                // onSearch={handleSearchGuest}
                onSelect={onSelectSeat}
                style={{ width: '100%' }}
                dropdownStyle={{ width: '100%' }}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  placeholder='Search seat number'
                  style={{ width: '100%' }}
                />
              </AutoComplete>
            </Col>
          )}
          <Col span={24}>
            <Row gutter={[10, 0]}>
              <Col span={12}>
                <Button
                  onClick={onFormClear}
                  className='allButtonsCancel'
                  style={{ width: '100%' }}
                >
                  {t('btn.19')}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  htmlType='submit'
                  className='allButtons'
                  style={{ width: '100%' }}
                >
                  {loading ? (
                    <Spin indicator={<LoadingOutlined spin />} size='small' />
                  ) : type === 'edit' ? (
                    t('btn.27')
                  ) : (
                    'Assign seat'
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Drawer>
  );
};

export default AssignGuestModel;
