import React, { useEffect } from 'react';
import LoadingIndicator from '../Screens/LoadingIndicator/LoadingIndicator';
import { Button, Col, Drawer, Input, Row, Spin, Typography } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './Layout.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  createEventLayout,
  getAllCommunityEvent,
  upateEventLayoutAction,
} from '../Actions/layoutAction';
import { LoadingOutlined } from '@ant-design/icons';

const EventLayoutModel = (props) => {
  const { open, onClose, eventId, type, data } = props;

  const { isCreateEventLayoutLoading, isUpdateEventLayoutLoading } =
    useSelector((state) => {
      const { layout } = state;
      const { isCreateEventLayoutLoading, isUpdateEventLayoutLoading } = layout;
      return { isCreateEventLayoutLoading, isUpdateEventLayoutLoading };
    });
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  console.log({ open, onClose, eventId, type, data });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFormClear = () => {
    reset();
  };
  const successCallback = (res) => {
    dispatch(getAllCommunityEvent(eventId));
    reset();
    onClose();
  };
  const failureCallback = (error) => {
    setError('name', {
      type: 'manual',
      message: error,
    });
    console.log({ error });
  };
  const onformSubmit = (formData) => {
    console.log({ formData, data });
    formData.community_event_id = eventId;
    if (type === 'create') {
      dispatch(createEventLayout(formData, successCallback, failureCallback));
    } else if (type === 'edit') {
      console.log('called edit api');

      dispatch(
        upateEventLayoutAction(
          { name: formData?.name },
          data?.id,
          successCallback,
          failureCallback
        )
      );
    }
  };
  useEffect(() => {
    if (open && data) {
      reset({ name: data?.name || '' });
    }
  }, [open, data, reset]);
  console.log({ errors });
  console.log({ isCreateEventLayoutLoading });
  const loading = isUpdateEventLayoutLoading || isCreateEventLayoutLoading;
  return (
    <Drawer
      open={open}
      footer={null}
      onClose={() => {
        onClose();
        reset();
      }}
      title='Add Event Layout'
    >
      <form onSubmit={handleSubmit(onformSubmit)}>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Typography className='FormInputLabel'>Layout name</Typography>
            <Controller
              as={<Input size='large' className='inputLabel' />}
              rules={{
                required: 'layout name required',
              }}
              defaultValue={data?.name || ''}
              name='name'
              control={control}
            />
            {errors?.name?.message && (
              <p style={{ color: 'red' }}>{errors?.name?.message}</p>
            )}
          </Col>
          <Col span={24}>
            <Row gutter={[10, 0]}>
              <Col span={12}>
                <Button
                  onClick={onFormClear}
                  className='allButtonsCancel'
                  style={{ width: '100%' }}
                >
                  {t('btn.19')}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  htmlType='submit'
                  className='allButtons'
                  style={{ width: '100%' }}
                >
                  {loading ? (
                    <Spin indicator={<LoadingOutlined spin />} size='small' />
                  ) : type === 'edit' ? (
                    t('btn.27')
                  ) : (
                    t('btn.18')
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Drawer>
  );
};

export default EventLayoutModel;
