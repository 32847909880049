import API from '../Utils/API';
import constants from '../Utils/constants';

export const createEventLayout =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.CREATE_EVENT_LAYOUT_REQUEST });
    API.post(`/api/layouts`, data)
      .then((response) => {
        dispatch({
          type: constants.CREATE_EVENT_LAYOUT_SUCCESS,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.CREATE_EVENT_LAYOUT_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

// export const getEventLayoutById =
//   (id, successCallback, failureCallback, parama) => (dispatch) => {
//     dispatch({ type: constants.GET_EVENT_LAYOUT_BY_ID_REQUEST });
//     API.get(`/api/layouts/${id}`)
//       .then((response) => {
//         dispatch({
//           type: constants.GET_EVENT_LAYOUT_BY_ID_SUCCESS,
//           payload: response.data,
//         });
//         successCallback && successCallback(response.data);
//       })
//       .catch((error) => {
//         dispatch({
//           type: constants.GET_EVENT_LAYOUT_BY_ID_FAILURE,
//         });
//         failureCallback && failureCallback(error.response.data?.error);
//       });
//   };

export const getAllCommunityEvent =
  (id, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.GET_EVENT_LAYOUT_BY_ID_REQUEST });
    API.get(`/api/layouts?community_event_id=${id}`)
      .then((response) => {
        dispatch({
          type: constants.GET_EVENT_LAYOUT_BY_ID_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.GET_EVENT_LAYOUT_BY_ID_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const upateEventLayoutAction =
  (data, id, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.UPDATE_EVENT_LAYOUT_REQUEST });
    API.put(`/api/layouts/${id}`, data)
      .then((response) => {
        dispatch({
          type: constants.UPDATE_EVENT_LAYOUT_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.UPDATE_EVENT_LAYOUT_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const getLayoutByIdAction =
  (id, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.GET_LAYOUT_BY_ID_REQUEST });
    API.get(`/api/layouts/${id}`)
      .then((response) => {
        dispatch({
          type: constants.GET_LAYOUT_BY_ID_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.GET_LAYOUT_BY_ID_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const crateLayoutRowAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.CREATE_LAYOUT_ROW_REQUEST });
    API.post(`/api/sections`, data)
      .then((response) => {
        dispatch({
          type: constants.CREATE_LAYOUT_ROW_SUCCESS,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.CREATE_LAYOUT_ROW_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const updateLayoutRowAction =
  (id, data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.UPDATE_LAYOUT_ROW_REQUEST });
    API.put(`/api/increase_number_of_seats/${id}`, data)
      .then((response) => {
        dispatch({
          type: constants.UPDATE_LAYOUT_ROW_SUCCESS,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.UPDATE_LAYOUT_ROW_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const importLayoutFileAction =
  (id, data, successCallback, failureCallback) => (dispatch) => {
    let formData = new FormData();
    formData.append('file_layout_params[import_csv]', data);
    dispatch({ type: constants.IMPORT_LAYOUT_FILE_REQUEST });
    API.put(`/api/import_export_csv/${id}`, formData)
      .then((response) => {
        dispatch({
          type: constants.IMPORT_LAYOUT_FILE_SUCCESS,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.IMPORT_LAYOUT_FILE_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const ExportLayoutFileAction =
  (id, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.EXPORT_LAYOUT_FILE_REQUEST });
    API.post(`/api/layouts/${id}/export_csv_files`)
      .then((response) => {
        dispatch({
          type: constants.EXPORT_LAYOUT_FILE_SUCCESS,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.EXPORT_LAYOUT_FILE_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };
export const getSeatByIdAction =
  (id, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.GET_SEAT_BY_ID_REQUEST });
    API.get(`/api/seats/${id}`)
      .then((response) => {
        dispatch({
          type: constants.GET_SEAT_BY_ID_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.GET_SEAT_BY_ID_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const UpdateSeatAction =
  (id, data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.UPDATE_SEAT_REQUEST });
    API.put(`/api/seats/${id}`, data)
      .then((response) => {
        dispatch({
          type: constants.UPDATE_SEAT_SUCCESS,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: constants.UPDATE_SEAT_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const SearchVisitorsAction =
  (url, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.SEARCH_VISITORS_REQUEST });
    API.get(`/api/visitors${url}`)
      .then((response) => {
        dispatch({
          type: constants.SEARCH_VISITORS_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        console.log({ error });
        dispatch({
          type: constants.SEARCH_VISITORS_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const AssignSeactToVisitorsAction =
  (id, data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.ASSIGN_SEAT_REQUEST });
    API.put(`/api/visitors/${id}`, data)
      .then((response) => {
        dispatch({
          type: constants.ASSIGN_SEAT_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        console.log({ error });
        dispatch({
          type: constants.ASSIGN_SEAT_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const deleteVisitorsAction =
  (successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.DELETE_VISITORS_REQUEST });
    API.delete(`/api/delete_all_visitor`)
      .then((response) => {
        dispatch({
          type: constants.DELETE_VISITORS_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        console.log({ error });
        dispatch({
          type: constants.DELETE_VISITORS_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const deleteLayoutSeatAction =
  (id, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.DELETE_SEAT_REQUEST });
    API.delete(`/api/seats/${id}`)
      .then((response) => {
        dispatch({
          type: constants.DELETE_SEAT_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        console.log({ error });
        dispatch({
          type: constants.DELETE_SEAT_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };

export const searchLayoutRowAction =
  (url, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: constants.DELETE_SEAT_REQUEST });
    API.get(`/api/sections?${url}`)
      .then((response) => {
        dispatch({
          type: constants.DELETE_SEAT_SUCCESS,
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        console.log({ error });
        dispatch({
          type: constants.DELETE_SEAT_FAILURE,
        });
        failureCallback && failureCallback(error.response.data?.error);
      });
  };
