import { combineReducers } from 'redux';
import authReducer from './authReducer';
import { communitiesReducer } from './communitiesReducer';
import { familyReducer } from './familyReducer';
import { familyMemberReducer } from './familyMemberReducer';
import { matrimonialReducer } from './matrimonialReducers';
import { communitiesNativeReducer } from './communitiesNativeReducer';
import { businessReducer } from './businessReducer';
import { eventsReducer } from './eventsReducer';
import { trusteesReducer } from './trusteesReducer';
import { usersReducer } from './usersReducer';
import { searchUserReducer } from './searchUserReducer';
import { familyAddressReducer } from './familyAddressReducer';
import { authenticationReducer } from './authenticationReducer';
import { designationsReducer } from './designationsReducer';
import { userFamilyReducer } from './userFamilyReducer';
import { communityCardsReducer } from './communityCardsReducer';
import { educationsReducer } from './educationReducer';
import { industryReducer } from './industriesReducer';
import { nativeVolunteerReducer } from './nativeVolunteerReducer';
import { publicActivityReducer } from './publicActivityReducer';
import { communityMeetingReducer } from './communityMeetingReducer';
import { FamiliesByNativeForVolunteerReducer } from './FamiliesByNativeForVolunteerReducer';
import { documentsReducer } from './documentsReducer';
import { contactReducer } from './contactReducer';
import { ticketsReducer } from './ticketReducer';
import { messageReducer } from './messageReducer';
import { windowMaintenanceReducer } from './windowMaintenanceReducer';
import { templatesReducer } from './templatesReducer';
import { templatesElementsReducer } from './templatesElementsReducer';
import { journeyReducer } from './journeyReducer';
import { footerRedirectionReducer } from './footerRedirectionReducer';
import { layoutReducer } from './layoutReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  communities: communitiesReducer,
  family: familyReducer,
  familyMember: familyMemberReducer,
  matrimonial: matrimonialReducer,
  business: businessReducer,
  events: eventsReducer,
  trustees: trusteesReducer,
  users: usersReducer,
  searchUser: searchUserReducer,
  communitiesNative: communitiesNativeReducer,
  familyAddresses: familyAddressReducer,
  authentication: authenticationReducer,
  designations: designationsReducer,
  userFamily: userFamilyReducer,
  educations: educationsReducer,
  industry: industryReducer,
  nativeVolunteers: nativeVolunteerReducer,
  publicActivity: publicActivityReducer,
  communityMeetings: communityMeetingReducer,
  FamiliesByNativeForVolunteer: FamiliesByNativeForVolunteerReducer,
  documents: documentsReducer,
  contacts: contactReducer,
  communityCards: communityCardsReducer,
  tickets: ticketsReducer,
  message: messageReducer,
  underMaintenance: windowMaintenanceReducer,
  templates: templatesReducer,
  templatesElements: templatesElementsReducer,
  journey: journeyReducer,
  footer: footerRedirectionReducer,
  layout: layoutReducer,
});

export default rootReducer;
