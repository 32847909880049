import React, { useEffect, useState } from 'react';
import './Layout.css';
import '../Screens/CommunityFeeds/CommunityFeeds.css';
import '../FindThePeoplePage/FindOtherPeople.css';
import { Button, Col, Empty, Row, Typography } from 'antd';
import EventLayoutModel from './EventLayoutModel';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCommunityEvent,
  getEventLayoutById,
} from '../Actions/layoutAction';
import { EditOutlined, LayoutOutlined } from '@ant-design/icons';
import { falseyValueCases } from '../Screens/CommonLogics/CommonMethods';
import { history } from '../Utils/history';
import LoadingIndicator from '../Screens/LoadingIndicator/LoadingIndicator';

const LayoutComponent = () => {
  const { eventLayoutById, isGetEventLayoutByIdLoading } = useSelector(
    (state) => {
      const { layout } = state;
      const { eventLayoutById, isGetEventLayoutByIdLoading } = layout;
      return { eventLayoutById, isGetEventLayoutByIdLoading };
    }
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedLayoutData, setSelectedlayoutData] = useState();
  const [isCrateLayoutOpen, setCreateLayoutModel] = useState({
    isLayoutModelVisible: false,
    layoutModelData: null,
    type: 'create',
  });
  const { isLayoutModelVisible, layoutModelData, type } = isCrateLayoutOpen;

  const handleCreateLayoutModel = (isOpen, type, layoutData) => {
    setCreateLayoutModel({
      isLayoutModelVisible: isOpen,
      layoutModelData: layoutData ? layoutData : null,
      type: type,
    });
  };
  const onLayoutOpenHandle = (data) => {
    // setSelectedlayoutData(data);
    history.push(`/events/viewlayout/${data?.id}`);
  };
  useEffect(() => {
    dispatch(getAllCommunityEvent(id));
  }, []);
  console.log({ eventLayoutById });
  console.log({ id, eventLayoutById, layoutModelData, type });
  return (
    <div className='LayoutContainer'>
      <LoadingIndicator loading={isGetEventLayoutByIdLoading}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Row justify='space-between' align='middle'>
              <Col>
                <Typography className='LayoutTitle'>Layouts</Typography>
              </Col>
              <Col>
                {' '}
                <Button
                  type='primary'
                  onClick={() => handleCreateLayoutModel(true, 'create')}
                  style={{ borderRadius: '4px', width: '100%' }}
                  className='button-search'
                >
                  Create layout
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {eventLayoutById?.length > 0 ? (
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <Row justify='space-between'>
                    <Col span={10}>
                      <Typography className='TableTitle'>
                        Layout name
                      </Typography>
                    </Col>
                    <Col span={2}>
                      <Typography className='TableTitle'>Total rows</Typography>
                    </Col>
                    <Col span={2}>
                      <Typography className='TableTitle'>
                        Total seats
                      </Typography>
                    </Col>
                    <Col span={3}>
                      <Typography className='TableTitle'>
                        Available seats
                      </Typography>
                    </Col>
                    <Col span={3}>
                      <Typography className='TableTitle'>
                        Occupied seats
                      </Typography>
                    </Col>
                    <Col span={4} className='TableTitle'>
                      <Typography className='TableTitle'>Action</Typography>
                    </Col>
                  </Row>
                </Col>
                {eventLayoutById?.map((layout, index) => {
                  return (
                    <Col span={24} key={index}>
                      <Row justify='space-between'>
                        <Col
                          span={10}
                          onClick={() => onLayoutOpenHandle(layout)}
                          style={{ cursor: 'pointer' }}
                        >
                          <Typography className='TableDataText'>
                            {layout?.name}
                          </Typography>
                        </Col>
                        <Col span={2}>
                          <Typography className='TableDataText'>
                            {layout?.total_row}
                          </Typography>
                        </Col>
                        <Col span={2}>
                          <Typography className='TableDataText'>
                            {layout?.total_seat}
                          </Typography>
                        </Col>
                        <Col span={3}>
                          <Typography className='TableDataText'>
                            {layout?.available_seats}
                          </Typography>
                        </Col>
                        <Col span={3}>
                          <Typography className='TableDataText'>
                            {layout?.occupied_seats}
                          </Typography>
                        </Col>
                        <Col span={4}>
                          <Typography className='TableDataText'>
                            <EditOutlined
                              onClick={() =>
                                handleCreateLayoutModel(true, 'edit', layout)
                              }
                            />
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <Row justify='center' align='middle'>
                <Col span={24}>
                  <div className='EmptyScreenCommunityCards'>
                    <Empty
                      description={
                        <Typography className='empty-details'>
                          Layout not found
                        </Typography>
                      }
                      image={<LayoutOutlined style={{ fontSize: '50px' }} />}
                    ></Empty>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <EventLayoutModel
          open={isLayoutModelVisible}
          data={layoutModelData}
          type={type}
          onClose={() => handleCreateLayoutModel(false, 'create', null)}
          eventId={id}
        />
      </LoadingIndicator>
    </div>
  );
};

export default LayoutComponent;
