import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import './FindOtherPeople.css';
import { Link } from 'react-router-dom';
import {
  Layout,
  Menu,
  Typography,
  Row,
  Col,
  Avatar,
  Dropdown,
  Space,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logoutCurrentUser } from '../Actions/authenticationAction';
import { Route } from 'react-router-dom';
import {
  MenuOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  BarChartOutlined,
  CalendarOutlined,
  LogoutOutlined,
  SearchOutlined,
  ShoppingOutlined,
  DownOutlined,
  EditOutlined,
  GlobalOutlined,
  UserAddOutlined,
  InfoCircleOutlined,
  PicRightOutlined,
  CustomerServiceOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { useBreakpoints } from '../Screens/CommonLogics/ResponsiveBreakPoints';
import { history } from '../Utils/history';
import { useTranslation } from 'react-i18next';
import EnglishButton from '../components/EnglishButton';
import GujaratiButton from '../components/GujaratiButton';
import UserUpdate from './UserUpdate';
import { getUsers } from '../Actions/usersAction';
import Admin from '../assets/Admin.svg';
import SamajIconSvg from '../assets/SamajIconSvg.svg';
import { getCommunityById } from '../Actions/communitiesAction';
import {
  CheckTokenExpired,
  newImageUrl,
} from '../Screens/CommonLogics/CommonMethods';
import HindiButton from '../components/HindiButton';
import { analytics } from '../components/Mixpanel/Mixpanel';
import { tracker } from '../components/openreplay';

const { Sider, Header, Content } = Layout;
const { Title } = Typography;
const backgroundColor = '#' + Math.floor(Math.random() * 1677).toString(14);
const Slider = (props) => {
  const { IsMD, IsSM, IsXS, IsLG } = useBreakpoints();
  const [collapsed, setCollapsed] = useState(
    IsXS || IsSM || IsMD ? true : false
  );
  const [isEditProfileModalVisible, setIsEditProfileModalVisible] =
    useState(false);
  const { itemKeyValue } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [itemKey, setItemKey] = useState(itemKeyValue ? itemKeyValue : 1);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const { currentUser, community, setCommunityId } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { community, setCommunityId } = state.communities;
    return {
      currentUser,
      community,
      setCommunityId,
    };
  });
  console.log({ community, currentUser });
  const localStorageCommunityId = localStorage.getItem('community_id');
  const checkTokenExpired = useCallback(() => {
    const isTokenExpired = CheckTokenExpired();
    if (isTokenExpired) {
      dispatch(logoutCurrentUser());
    }
  }, [dispatch]);
  useEffect(() => {
    checkTokenExpired();
  }, [checkTokenExpired]);

  const communityByIdSuccess = (response) => {
    const data = response.data;
    analytics?.people.set({
      communityId: data?.id,
      communityName: data?.name,
    });
  };
  const communityByIdFailure = (error) => {};
  const GetUsersAndGetCommunityById = useCallback(() => {
    dispatch(getUsers());
    dispatch(
      getCommunityById(
        localStorageCommunityId ? localStorageCommunityId : setCommunityId,
        communityByIdSuccess,
        communityByIdFailure
      )
    );
  }, []);

  useEffect(() => {
    GetUsersAndGetCommunityById();
  }, [GetUsersAndGetCommunityById]);

  const { users_types } = currentUser || {};
  const logoutUserMethod = () => {
    dispatch(logoutCurrentUser());
  };
  const { component, ...rest } = props;
  const Component = component;
  const heading = [
    {
      title: t('navigationHeader.16'),
      path: '/search',
      icon: <SearchOutlined />,
    },
    {
      title: t('navigationHeader.11'),
      path: '/add_family',
      icon: <UsergroupAddOutlined />,
    },
    {
      title: t('navigationHeader.13'),
      path: '/events',
      icon: <CalendarOutlined />,
    },
    {
      title: t('navigationHeader.12'),
      path: '/trustees',
      icon: <BarChartOutlined />,
    },
    {
      title: t('navigationHeader.14'),
      path: '/matrimonials',

      icon: <UserOutlined />,
    },
    {
      title: t('navigationHeader.15'),
      path: '/business_profiles',
      icon: <ShoppingOutlined />,
    },
    {
      title: 'Help And Support',
      path: '/support',
      icon: <ShoppingOutlined />,
    },
  ];
  const customClick = (data) => {
    setItemKey(data);
  };
  const businessDropDownMenu = (
    <Menu>
      <Menu.Item key='9'>
        <Link to='/business_profile'>{t('navigationHeader.4')}</Link>
      </Menu.Item>
      <Menu.Item key='10'>
        <Link to='/jobProfile'>{t('navigationHeader.5')}</Link>
      </Menu.Item>
    </Menu>
  );

  const PathArray = history.location.pathname.split('/');
  const ProfileId = PathArray[PathArray.length - 1];

  let headingTitle = '';
  let defaultSelectedSliderKey = '';
  if (history.location.pathname === '/trustees') {
    headingTitle = t('navigationHeader.12');
    defaultSelectedSliderKey = 4;
  } else if (history.location.pathname === '/events') {
    headingTitle = t('navigationHeader.13');
    defaultSelectedSliderKey = 3;
  } else if (history.location.pathname === '/matrimonials') {
    headingTitle = t('navigationHeader.14');
    defaultSelectedSliderKey = 5;
  } else if (history.location.pathname === '/business_profiles') {
    headingTitle = t('navigationHeader.15');
    defaultSelectedSliderKey = 6;
  } else if (history.location.pathname === '/search') {
    headingTitle = t('navigationHeader.16');
    defaultSelectedSliderKey = 1;
  } else if (history.location.pathname === '/add_family') {
    headingTitle = t('navigationHeader.11');
    defaultSelectedSliderKey = 2;
  } else if (history.location.pathname === '/admin') {
    headingTitle = t('content.161');
    defaultSelectedSliderKey = 77;
  } else if (history.location.pathname === '/informations') {
    headingTitle = t('navigationHeader.17');
    defaultSelectedSliderKey = 87;
  } else if (history.location.pathname === '/community_feeds') {
    headingTitle = t('content.256');
    defaultSelectedSliderKey = 11;
  } else if (history.location.pathname === '/support') {
    headingTitle = t('content.260');
    defaultSelectedSliderKey = 88;
  } else if (
    history.location.pathname === `/businessProfileDetails/${ProfileId}`
  ) {
    headingTitle = t('navigationHeader.15');
  } else if (history.location.pathname === `/matrimonials/${ProfileId}`) {
    headingTitle = t('navigationHeader.14');
  } else if (history.location.pathname === `/families/${ProfileId}`) {
    headingTitle = t('navigationHeader.11');
  } else if (history.location.pathname === `/user/${ProfileId}`) {
    headingTitle = t('navigationHeader.18');
  } else if (
    history.location.pathname === `/communities/${setCommunityId}/informations`
  ) {
    headingTitle = t('navigationHeader.17');
  } else {
    headingTitle = t('content.155');
    defaultSelectedSliderKey = 78;
  }
  const openEditProfileMethod = () => {
    setIsEditProfileModalVisible(true);
  };
  const isUserVolunteer = users_types?.includes('volunteer');
  const isUserAdmin = users_types?.includes('admin');
  const [newUserImage, SetNewUserImage] = useState(null);
  const [selectedLanguage, setSelectedlanguage] = useState('en');

  useEffect(() => {
    (async function () {
      if (currentUser?.user_image?.image) {
        const url = await newImageUrl(currentUser?.user_image?.image);
        SetNewUserImage(url);
      }
    })();
  }, [currentUser?.user_image?.image]);

  useEffect(() => {
    tracker.setUserID(currentUser?.first_name + currentUser?.last_name);
    tracker.setMetadata('phoneNumber', currentUser?.phone_with_country_code);
    tracker.setMetadata('CommunityName', community?.name);
  }, []);

  const items = [
    {
      label: (
        <Row>
          <Col>
            <img
              src={SamajIconSvg}
              style={{
                height: '60px',
              }}
              className='sidebarSamajImage'
              alt=''
            />
          </Col>

          {(IsXS || IsSM) && (
            <Row justify='end' style={{ width: '80%' }}>
              <Col
                onClick={() => toggle()}
                style={{
                  cursor: 'pointer',
                }}
              >
                <MenuOutlined style={{ paddingLeft: 4 }} />
              </Col>
            </Row>
          )}
        </Row>
      ),
      key: '33',
      className: 'sidebarSamajTitle',
    },
    {},
    {
      label: t('content.256'),
      key: '11',
      icon: <PicRightOutlined />,
      onClick: () => history.push('/community_feeds'),
      className: 'menuItem',
    }, // which is required
    community?.show_find_other_people &&
      currentUser?.verified && {
        label: t('navigationHeader.16'),
        key: '1',
        icon: <SearchOutlined />,
        onClick: () => {
          analytics?.track('Main_FindOtherPeople');
          history.push('/search');
        },
        className: 'menuItem',
      }, // which is required
    {
      label: t('navigationHeader.11'),
      key: '2',
      icon: <UsergroupAddOutlined />,
      onClick: () => {
        analytics?.track('Main_Families');
        history.push('/add_family');
      },
      className: 'menuItem',
    },
    community?.show_event &&
      currentUser?.verified && {
        label: t('navigationHeader.13'),
        key: '3',
        icon: <CalendarOutlined />,
        onClick: () => {
          analytics?.track('Main_Events');
          history.push('/events');
        },
        className: 'menuItem',
      },
    community?.show_matrimonial &&
      currentUser?.verified && {
        label: t('navigationHeader.14'),
        key: '5',
        icon: <UserOutlined />,
        onClick: () => {
          analytics?.track('Main_Matrimonials');
          history.push('/matrimonials');
        },
        className: 'menuItem',
      },
    community?.show_business_profile &&
      currentUser?.verified && {
        label: t('navigationHeader.15'),
        key: '6',
        icon: <ShoppingOutlined />,
        onClick: () => {
          analytics?.track('Main_Buisness_Profile');
          history.push('/business_profiles');
        },
        className: 'menuItem',
      },
    isUserVolunteer && {
      label: t('content.155'),
      key: '78',
      icon: <UserAddOutlined />,
      onClick: () => {
        analytics?.track('Main_Volunteers');
        history.push('/new_volunteers');
      },
      className: 'menuItem',
    },
    {
      label: t('navigationHeader.17'),
      key: '87',
      icon: <InfoCircleOutlined />,
      onClick: () => {
        analytics?.track('Main_Information');
        history.push(`/communities/${localStorageCommunityId}/informations`);
      },
      className: 'menuItem',
    },
    isUserAdmin && {
      label: t('content.161'),
      key: '77',
      icon: <img src={Admin} alt='Admin' />,
      onClick: () => {
        analytics?.track('Main_Admin');
        history.push('/admin');
      },
      className: 'menuItem',
    },
    {
      label: t('content.260'),
      key: '88',
      icon: <CustomerServiceOutlined />,
      onClick: () => {
        analytics?.track('Main_Support');
        history.push('/support');
      },
      className: 'menuItem',
    },
    IsXS && {
      label: currentUser?.first_name,
      key: 'submenu',
      icon: (
        <Avatar
          style={{
            marginRight: 15,
            cursor: 'pointer',
            backgroundColor: backgroundColor,
          }}
          shape='square'
          src={newUserImage}
        >
          {currentUser?.first_name?.[0]}
        </Avatar>
      ),
      children: [
        {
          label: t('content.140'),
          key: '16',
          icon: <EditOutlined />,
          className: 'menuItem',
        },
      ],
      onClick: () => {
        analytics?.track('Main_EditProfile');
        openEditProfileMethod();
      },
      className: 'menuItem',
    },
    IsXS && {
      label: 'Select languages',
      key: '',
      icon: <GlobalOutlined />,
      children: [
        {
          label: <GujaratiButton />,
          key: '20',
          icon: 'G',
          className: 'menuItem',
        },
        {
          label: <EnglishButton />,
          key: '21',
          icon: 'E',
          className: 'menuItem',
        },
        {
          label: <HindiButton />,
          key: '22',
          icon: 'H',
          className: 'menuItem',
        },
      ],
      className: 'menuItem',
    },
    IsXS && {
      label: t('navigationHeader.10'),
      key: '8',
      icon: <LogoutOutlined />,
      onClick: () => {
        analytics?.track('Main_Logout');
        logoutUserMethod();
      },
      className: 'menuItem',
    },
  ];

  const languages = (
    <Menu>
      <Menu.Item key='hi' onClick={() => setSelectedlanguage('hi')}>
        <HindiButton />
      </Menu.Item>
      <Menu.Item key='gu' onClick={() => setSelectedlanguage('gu')}>
        <GujaratiButton />
      </Menu.Item>
      <Menu.Item key='en' onClick={() => setSelectedlanguage('en')}>
        <EnglishButton />
      </Menu.Item>
    </Menu>
  );
  const LogoutAndEditProfiles = (
    <Menu>
      <Menu.Item
        key='Edit'
        onClick={() => {
          analytics?.track('Main_EditProfile');
          openEditProfileMethod();
        }}
        className='menuItem'
      >
        <Space>
          <EditOutlined />
          {t('content.140')}
        </Space>
      </Menu.Item>
      <Menu.Item
        key='Logout'
        onClick={() => {
          analytics?.track('Main_logout');
          logoutUserMethod();
        }}
        className='menuItem'
      >
        <Space>
          <LogoutOutlined />
          {t('navigationHeader.10')}
        </Space>
      </Menu.Item>
    </Menu>
  );
  return (
    <Route
      {...rest}
      render={() => {
        return (
          <>
            <Col>
              <Layout className='findOtherPeopleLayout'>
                <Sider
                  trigger={null}
                  collapsible
                  collapsed={collapsed}
                  style={{
                    background: '#FFFFFF',
                    borderadius: '2px',
                    borderRight: '1px solid #EEEEEE',
                  }}
                  collapsedWidth={0}
                  width={IsXS || IsSM ? '100%' : 250}
                >
                  <Row></Row>

                  <Menu
                    onClick={(e) => customClick(e.key)}
                    mode='inline'
                    selectedKeys={[
                      `${
                        defaultSelectedSliderKey
                          ? defaultSelectedSliderKey
                          : itemKey
                      }`,
                    ]}
                    style={{ background: '#FBFBFB', paddingTop: '25px' }}
                    items={items}
                  />
                  {isEditProfileModalVisible && (
                    <>
                      <UserUpdate
                        isEditProfileModalVisible={isEditProfileModalVisible}
                        setIsEditProfileModalVisible={
                          setIsEditProfileModalVisible
                        }
                        currentUser={currentUser}
                        setCollapsed={setCollapsed}
                      />
                    </>
                  )}
                </Sider>
                <Layout>
                  <Header className='header-background'>
                    <Row
                      align='middle'
                      justify='space-between'
                      gutter={[{ md: 16, lg: 16, sm: 16, xs: 20 }, 8]}
                    >
                      {/* <Col lg={4}>
                        <Row align='middle' justify='space-around'>
                          <Col
                            onClick={() => toggle()}
                            style={{ cursor: 'pointer' }}
                          >
                            <MenuOutlined />
                          </Col>

                          <Col>
                            {heading[itemKey - 1]?.title !==
                              t('navigationHeader.4') && (
                              <Title
                                level={5}
                                style={{ marginTop: '7px' }}
                                className='headerText'
                              >
                                {headingTitle}
                              </Title>
                            )}
                            {heading[itemKey - 1]?.title ===
                              t('navigationHeader.4') && (
                              <Dropdown
                                overlay={businessDropDownMenu}
                                trigger={['click']}
                              >
                                <Title level={5} style={{ cursor: 'pointer' }}>
                                  {t('navigationHeader.4')} <DownOutlined />
                                </Title>
                              </Dropdown>
                            )}
                          </Col>
                        </Row>
                      </Col> */}

                      <Col
                        xl={1}
                        lg={2}
                        md={1}
                        sm={1}
                        xs={2}
                        onClick={() => toggle()}
                        style={{ cursor: 'pointer', paddingLeft: '30px' }}
                      >
                        <MenuOutlined style={{ paddingLeft: 4 }} />
                      </Col>
                      <Col xl={6} lg={8} md={10} sm={12} xs={13}>
                        {heading[itemKey - 1]?.title !==
                          t('navigationHeader.4') && (
                          <Title
                            level={5}
                            style={{ marginTop: '7px' }}
                            className='headerText'
                          >
                            {headingTitle}
                          </Title>
                        )}
                        {heading[itemKey - 1]?.title ===
                          t('navigationHeader.4') && (
                          <Dropdown
                            overlay={businessDropDownMenu}
                            trigger={['click']}
                          >
                            <Title level={5} style={{ cursor: 'pointer' }}>
                              {t('navigationHeader.4')} <DownOutlined />
                            </Title>
                          </Dropdown>
                        )}
                      </Col>
                      <Col xl={2} lg={2} md={1} sm={0} xs={0}></Col>

                      <Col xl={14} lg={11} md={11} sm={9} xs={11}>
                        <Row justify='end'>
                          {IsXS || (
                            <Col style={{ marginRight: '36px' }}>
                              <Row align='middle' gutter={[87, 0]}>
                                <Col
                                  style={{
                                    marginTop: '7px',
                                  }}
                                >
                                  <Dropdown
                                    overlay={languages}
                                    trigger={['click']}
                                  >
                                    <Title
                                      level={5}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <Space>
                                        <Typography
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Montserrat',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                          }}
                                        >
                                          {selectedLanguage.toUpperCase()}
                                        </Typography>
                                        <CaretDownOutlined
                                          style={{ color: '#2ACCFF' }}
                                        />
                                      </Space>
                                    </Title>
                                  </Dropdown>
                                </Col>

                                <Col>
                                  <Dropdown
                                    overlay={LogoutAndEditProfiles}
                                    trigger={['click']}
                                  >
                                    <Space>
                                      <Typography
                                        style={{
                                          color: '#303134',
                                          fontFamily: 'Montserrat',
                                          fontSize: '18px',
                                          fontweight: 400,
                                        }}
                                      >
                                        {currentUser?.first_name +
                                          ' ' +
                                          currentUser?.last_name}{' '}
                                        <br />
                                        {currentUser?.native[0]}
                                      </Typography>
                                      <Avatar
                                        style={{
                                          cursor: 'pointer',
                                          background: backgroundColor,
                                          width: '60px',
                                          height: '60px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: '10px',
                                        }}
                                        shape='square'
                                        src={newUserImage}
                                      >
                                        {currentUser?.first_name?.[0]}
                                      </Avatar>
                                    </Space>
                                  </Dropdown>
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Header>
                  <Content>
                    <Component />
                  </Content>
                </Layout>
              </Layout>
            </Col>
          </>
        );
      }}
    />
  );
};

export default Slider;
